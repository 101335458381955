;
(function($){
// Doc Ready
$(document).ready(function() {
  window.initOwlCarousel = function () {
    $('.owl-carousel').each(function () {
      var loop = $(this).data('loop');
      var center = $(this).data('center');
      var mouseDrag = $(this).data('mouse-drag');
      var items_lg = $(this).data('items-lg');
      var items_md = $(this).data('items-md');
      var autoplay = $(this).data('autoplay');
      var autoplayHoverPause = $(this).data('autoplayHoverPause');
      var autoplayTimeout = $(this).data('autoplayTimeout');

      items_lg = items_lg ? items_lg : items_md;

      var responsive = {
        1024: {
          items: items_lg ? items_lg : 1,
        },
        768: {
          items: items_md ? items_md : 1,
        }
      };

      var config = {
        items : 1,
        loop : loop ? loop : false,
        center : center ? center : false,
        responsive: responsive,
        mouseDrag: mouseDrag !== undefined ? mouseDrag : true,
        autoplay: autoplay ? autoplay : true,
        autoplayHoverPause: autoplayHoverPause ? autoplayHoverPause : true,
        autoplayTimeout: autoplayTimeout ? autoplayTimeout : 4000,
      };

      $(this).owlCarousel(config);
    });
  };

  window.initOwlCarousel();

  // END INIT CAROUSEL

  // SCROLL BUTTON
  $(window).scroll(function() {
    scrollPos = $(window).scrollTop();

    $('#scroll-to-top')[scrollPos > 600 ? 'addClass' : 'removeClass']('show');

    if ($('#scroll-to-top').offset().top > ($('.footer-bottom').offset().top - 20)) {
      $('#scroll-to-top').css('bottom', '10rem');
    } else {
      $('#scroll-to-top').css('bottom', '2rem');
    }
  });

  $('#scroll-to-top').on('click', function() {
    $('html, body').animate({
      scrollTop: 0
    }, 250);
    return false;
  });
  // END SCROLL BUTTON

  //Hide images on twitter widget
  var hideTwitterImages = function(widget) {
    var $twitterWidget = jQuery(widget);
    var $tweetList = $twitterWidget.contents().find('.timeline-Tweet');
    var height = $twitterWidget.outerHeight();

    $twitterWidget.contents().find('.timeline-Header').css('display', 'none');
    $twitterWidget.contents().find('.timeline-Footer').css('display', 'none');
    $twitterWidget.contents().find('a.customisable').css('color', '#0acc82');
    $twitterWidget.contents().find('.customisable-border').css('border', '0px');

    $tweetList.each(function(index, element) {
      var $current = jQuery(element);

      height = height - $current.find('.timeline-Tweet-media').outerHeight();
      $current.find('.timeline-Tweet-media').css('display', 'none');
    });

    $twitterWidget.css('height', height);
    $twitterWidget.parents('.twitter-block').removeClass('d-none');
  };

  window.twttr = (function(d, s, id) {
    var js, fjs = d.getElementsByTagName(s)[0],
      t = window.twttr || {};
    if (d.getElementById(id)) return;
    js = d.createElement(s);
    js.id = id;
    js.src = "https://platform.twitter.com/widgets.js";
    fjs.parentNode.insertBefore(js, fjs);

    t._e = [];
    t.ready = function(f) {
      t._e.push(f);
    };

    return t;
  }(document, "script", "twitter-wjs"));

  if (undefined !== window.twttr) {
    window.twttr.ready(function (twttr) {
      window.twttr.events.bind('rendered', function (evt) {
        hideTwitterImages(evt.target);
      });
    });
  }
  // Overlay
  function showOverlay() {
    $('body').append('<div class="modal-backdrop fade show"></div>');
  }

  function hideOverlay() {
    $('.modal-backdrop').remove();
  }

  function arrowDown() {
    $('.icon-arrow-outline').removeClass('active');
  }

  // Disable Scroll
  function disactiveScroll() {
    $('body').addClass('no-scroll');
    showOverlay();
  }

  function activeScroll() {
    $('body').removeClass('no-scroll');
    hideOverlay();
    arrowDown();
  }

  // close button
  $('.menu-item-wrapper').find('.btn-close').on('click', function() {
    $(this).parents('.menu-item-wrapper').collapse('hide');
    $('.fc-has-submenu').find('.icon-arrow-outline').removeClass('active');
  });

  $(document).on('click', function(e) {
    if ($(e.target).hasClass('modal-backdrop')) {
      $('.fc-submenu.collapse').collapse('hide');
    }
  });

  // Menu has submenu
  $('.fc-has-submenu').on('click', function() {
    navItemVal = $(this).attr('data-value');

    $('.menu-item-wrapper.collapse').collapse('hide');
    $('#' + navItemVal).collapse('toggle');

    var $arrow = $(this).find('.icon-arrow-outline');

    if ($arrow.length > 0) {
      $(this).siblings().find('.icon-arrow-outline').removeClass('active');
      $(this).find('.icon-arrow-outline').toggleClass('active');
    }
  });

  $('.fc-submenu.collapse').on('show.bs.collapse', disactiveScroll);

  $('.fc-submenu.collapse').on('hide.bs.collapse', activeScroll);

  // Searchbar
  $('.searchbar-wrapper').on('click', function() {
    $(this).find('input').focus();
  });

  $('.search-wrapper').find('input').focus(function() {
    $('.search-wrapper').find('.placeholder').addClass('d-none');
  });

  $('.search-wrapper').find('input').blur(function() {
    $('.search-wrapper').find('.placeholder')[$(this).val().length >= 1 ? 'addClass' : 'removeClass']('d-none');
  });

  $('.search-wrapper').find('input').on('input', function(){
    $('.search-wrapper').find('.search-btn')[$(this).val().length >= 1 ? 'removeClass' : 'addClass']('disabled');
  });

  // Sticky megamenu
  var offset = $('.megamenu').offset().top - 60 - 90;
  var offsetSticky = $('.megamenu').offset().top - 30 - 60;

  $(window).on('scroll', function() {
    $('.is-home').find('.megamenu')[$(window).scrollTop() > offset ? 'addClass' : 'removeClass']('sticky');
    $('.fixed-top.is-home')[$(window).scrollTop() > offset ? 'addClass' : 'removeClass']('sticky');

    if ($(window).scrollTop() < offset) {
      $('.megamenu-nav-item').collapse('hide');
    }
  });

  // Scroll
  $('.megamenu-item.fc-has-submenu').on('click', function() {
    if ($(window).scrollTop() < offsetSticky ) {
      $('html, body').animate({
        scrollTop: offset + 1
      }, 100);
      return false;
    }
  });

  $('#megamenu-item-1-collaps').on('click', function() {
    $('#megamenu-item-1').collapse('toggle');
  });

  /* Menu mobile */
  // Navbar Toggle
  $('.nav-mobile_toggle').on('click', function() {
    $(this).toggleClass('menu-shown');
    $('.nav-mobile_nav').toggleClass('show');
    $('body').toggleClass('no-scroll');
  });

  $('[class*="link-to-level"]').on('click', function() {
    $(this).next('[class*="nav-mobile_nav-level-"]').addClass('show');
  });

  $('.back-to').on('click', function() {
    $(this).closest('[class*="nav-mobile_nav-level-"]').removeClass('show');
  });

  $('.btn-mobile-rs').on('click', function() {
    $('.mobile-rs-wrapper').toggleClass('show');
    $(this).toggleClass('show-close bg-primary text-white');

    if ($(this).hasClass('show-close')) {
      $(this).html('<i class="icon-fc-close-alt"></i>Fermer')
    } else {
      $(this).html('<i class="icon-fc-share"></i>Suivez-nous')
    }

    $('.mobile-sites-wrapper').removeClass('show');
    $('.btn-mobile-sites').removeClass('show-close bg-primary text-white').html('<i class="icon-fc-share"></i>Autres sites');
  });

  $('.btn-mobile-sites').on('click', function() {
    $('.mobile-sites-wrapper').toggleClass('show');
    $(this).toggleClass('show-close bg-primary text-white');

    if ($(this).hasClass('show-close')) {
      $(this).html('<i class="icon-fc-close-alt"></i>Fermer');
    } else {
      $(this).html('<i class="icon-fc-share"></i>Autres sites');
    }

    $('.mobile-rs-wrapper').removeClass('show');
    $('.btn-mobile-rs').removeClass('show-close bg-primary text-white').html('<i class="icon-fc-share"></i>Autres sites');
  });

  // Cookies conditions handler
  // if (undefined === Cookies.get('accepted_cookie')) {
  //   $('.cookie-wrapper').removeClass('d-none');

  //   $('.btn-accept-cookies').on('click', function () {
  //     Cookies.set('accepted_cookie', 1, { expires: 30 });
  //     $('.cookie-wrapper').addClass('d-none');
  //   });
  // }

  // $('.cookie-wrapper').find('.btn-close').on('click', function() {
  //   var cookieHeight = $('.cookie-wrapper').outerHeight();
  //   $('.cookie-wrapper').css({'bottom': -cookieHeight, 'box-shadow': 'none'});
  // });

  //Search results
  function clearBtn() {
    $('.searchbar-in-page').find('.btn-clear')[$('.searchbar-in-page').find('input').val() ? 'removeClass' : 'addClass']('d-none');
  }
  clearBtn();

  $('.searchbar-in-page').find('.btn-clear').on('click', function() {
    $('.searchbar-in-page').find('input').val('').focus();
    $('.search-result-wrapper').css('opacity', .3);
    clearBtn();
  });

  $('.searchbar-in-page').find('input').on('blur', function() {
    $('.search-result-wrapper').css('opacity', 1);
    $(this).find('.btn-clear')[$(this).val() ? 'removeClass' : 'addClass']('d-none');
  });

  $('.searchbar-in-page').find('input').on('keyup', function() {
    clearBtn();
  });

  // Regional map
  $('.regional-map-block').find('.region-mark').on('click', function() {
    var regionVal = $(this).attr('data-value');

    $('.regional-map-block').find('adress').removeClass('show');
    $('.regional-map-block').find('.region-mark').addClass('disabled');

    $(this).next('adress').addClass('show');
    $(this).removeClass('disabled');

    $('.region-list').find('.' + regionVal).addClass('active');
    $('.region-list').find('.' + regionVal + ' .icon-arrow-outline').addClass('green');
  });

  $('.regional-map-block').find('.btn-close').on('click', function() {
    var regionVal = $(this).parents('li').find('.region-mark').attr('data-value');

    $(this).parents('adress').removeClass('show');
    $('.regional-map-block').find('.region-mark').removeClass('disabled');

    $('.region-list').find('.' + regionVal).removeClass('active');
    $('.region-list').find('.' + regionVal + ' .icon-arrow-outline').removeClass('green');
  });

  $('.region-list').find('.region-list-item').on('mouseenter', function() {
    var regionVal = $(this).attr('data-value');

    $(this).addClass('active');
    $(this).find('.icon-arrow-outline').addClass('green');

    $('.regional-map-block').find('adress').removeClass('show');
    $('.regional-map-block').find('.region-mark').addClass('disabled');

    $('.regional-map-block').find('.' + regionVal).removeClass('disabled');
    $('.regional-map-block').find('.' + regionVal).next('adress').addClass('show');
  });

  $('.region-list').find('.region-list-item').on('mouseleave', function() {
    $(this).removeClass('active');
    $(this).find('.icon-arrow-outline').removeClass('green');
  });

  // SSO logout
  $('.logout').off('click').on('click', function () {
    var $url = $('#cloud-ui').data('api') + 'security/session',
        $href = $(this).find('a:first').data('href');
    $.ajax({
        url: $url,
        type: 'DELETE'
    }).always(function () {
        document.cookie='PHPSESSID=; expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/';
        window.location.href = $href;
    });
  });

  // Toggle filters
  $('.block-news-thread').find('.filter-toggler').on('click', function() {
    $(this).next('.filter-content-wrapper').collapse('toggle');
  });

  setTimeout(function() {
    if ($('#cloud-ui').find('.cloud-toolbar-main').length != 0) {
      setTimeout(function() {
        window.cloud.dndZone.apply();
      }, 2000);
    }
  }, 2000);

  // Locked news
  $('.lock-item').on('click', function() {
    var self = $(this);
    var redirectUri = $("#lockItemModal").data('redirecturi');
    if ('undefined' !== typeof self.data('redirecturi')) {
      redirectUri = self.data('redirecturi');
    }
    $("#lockItemModal").find('button.redirecturi').data('link', redirectUri);
    $("#lockItemModal").modal();
    return false;
  });
// END Doc Ready
});

})(jQuery);
